import instance from '../utils/axios';

const API_STAT_HOST = process.env.REACT_APP_STAT_HOST;
const API_SERVICE_HOST = process.env.REACT_APP_SERVICE_HOST;

export const statsApi = {
    fetchChartData(dateFrom, dateTo) {
        return instance(API_STAT_HOST).get(
            `/v1/user/lead/statistic?dateFrom=${dateFrom}&dateTo=${dateTo}`, { withCredentials: true }
        );
    },
    fetchTableData(query) {
        return instance(API_SERVICE_HOST).get(
            `/v1/user/lead-send-processes?${query}`, { withCredentials: true }
        );
    },
    startExport(createdFrom, createdTo, filters, page = 1, perPage = 10) {
        let query = `page=${page}&perPage=${perPage}&createdFrom=${encodeURIComponent(createdFrom)}&createdTo=${encodeURIComponent(createdTo)}`;
        Object.keys(filters).forEach((key) => {
            const value = filters[key];
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    query += `&${key}[]=${encodeURIComponent(item)}`;
                });
            } else {
                query += `&${key}=${encodeURIComponent(value)}`;
            }
        });
        return instance(API_SERVICE_HOST).get(`/v1/user/lead-send-processes/export?${query}`, { withCredentials: true });
    },
    fetchExportPage(hash, scrollId, page, perPage = 10, createdFrom, createdTo, filters) {
        let query = `exportHash=${hash}&scrollId=${scrollId}&page=${page}&perPage=${perPage}&createdFrom=${encodeURIComponent(createdFrom)}&createdTo=${encodeURIComponent(createdTo)}`;
        
        Object.keys(filters).forEach((key) => {
            const value = filters[key];
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    query += `&${key}[]=${encodeURIComponent(item)}`;
                });
            } else {
                query += `&${key}=${encodeURIComponent(value)}`;
            }
        });
    
        return instance(API_SERVICE_HOST).get(`/v1/user/lead-send-processes/export?${query}`, { withCredentials: true });
    }
};
