import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postbackApi } from "../../../api/postbacks";

const initialState = {
  postbacks: [],
  updateSuccess: false,
  triggers: [],
  loading: false,
  error: null,
};

export const fetchPostbacks = createAsyncThunk(
  'postback/fetchPostbacks',
  async ({ page = 1, perPage = 100 }, { rejectWithValue }) => {
    try {
      const { data } = await postbackApi.fetchPostbacks(page, perPage);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch postbacks');
    }
  }
);

export const fetchPostbackTriggers = createAsyncThunk(
  'postback/fetchPostbackTriggers',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await postbackApi.fetchPostbackTriggers();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch postback triggers');
    }
  }
);

export const createPostback = createAsyncThunk(
  'postback/createPostback',
  async (postbackData, { rejectWithValue }) => {
    try {
      const { data } = await postbackApi.createPostback(postbackData);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create postback');
    }
  }
);

export const updatePostback = createAsyncThunk(
  'postback/updatePostback',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await postbackApi.updatePostback(id, data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update postback');
    }
  }
);

export const deletePostback = createAsyncThunk(
  'postback/deletePostback',
  async (id, { rejectWithValue }) => {
    try {
      await postbackApi.deletePostback(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete postback');
    }
  }
);

const postbackSlice = createSlice({
  name: 'postback',
  initialState,
  reducers: {
    updatePostbackSuccess(state) {
      state.updateSuccess = true;
    },
    clearUpdateSuccess(state) {
      state.updateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostbacks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostbacks.fulfilled, (state, action) => {
        state.loading = false;
        state.postbacks = Array.isArray(action.payload.items) ? action.payload.items : [];
      })
      .addCase(fetchPostbacks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPostbackTriggers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostbackTriggers.fulfilled, (state, action) => {
        state.loading = false;
        state.triggers = action.payload || [];
      })
      .addCase(fetchPostbackTriggers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPostback.fulfilled, (state, action) => {
        state.postbacks.push(action.payload);
      })
      .addCase(updatePostback.fulfilled, (state, action) => {
        const index = state.postbacks.findIndex((postback) => postback.id === action.payload.id);
        if (index !== -1) {
          state.postbacks[index] = action.payload;
        }
        state.updateSuccess = true;
      })
      .addCase(deletePostback.fulfilled, (state, action) => {
        state.postbacks = state.postbacks.filter((postback) => postback.id !== action.payload);
      });
  },
});

export const { updatePostbackSuccess, clearUpdateSuccess } = postbackSlice.actions;

export default postbackSlice.reducer;
