import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { paymentsApi } from "../../../api/payments";


export const fetchPayments = createAsyncThunk(
    "payments/fetchPayments",
    async ({ page = 1, perPage = 100 }, { rejectWithValue }) => {
        try {
            const response = await paymentsApi.fetchPayments(page, perPage);
            return {
                items: response.data.data.items,
                pagination: response.data.data.pagination,
                totalPages: response.data.data.pagination.max,
            };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Failed to fetch payments.");
        }
    }
);

export const fetchPaymentMethods = createAsyncThunk(
    "payments/fetchPaymentMethods",
    async (_, { rejectWithValue }) => {
        try {
            const response = await paymentsApi.fetchPaymentMethods();
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Failed to fetch payment methods.");
        }
    }
);

export const createPayment = createAsyncThunk(
    "payments/createPayment",
    async (paymentData, { rejectWithValue }) => {
        try {
            const response = await paymentsApi.createPayment(paymentData);
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Failed to create payment.");
        }
    }
);


const paymentsSlice = createSlice({
    name: "payments",
    initialState: {
        payments: [],
        paymentMethods: [],
        pagination: {
            page: 1,
            max: 1,
            hasMore: false,
        },
        loading: false,
        error: null,
        successMessage: null,
    },
    reducers: {
        clearMessages(state) {
            state.error = null;
            state.successMessage = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPayments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPayments.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.payments = payload.items;
                state.pagination = payload.pagination;
                state.totalPages = payload.totalPages;
            })
            .addCase(fetchPayments.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchPaymentMethods.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPaymentMethods.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.paymentMethods = payload;
            })
            .addCase(fetchPaymentMethods.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(createPayment.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.successMessage = null;
            })
            .addCase(createPayment.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.successMessage = "Payment request sent successfully.";
            })
            .addCase(createPayment.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export const { clearMessages } = paymentsSlice.actions;

export default paymentsSlice.reducer;
