import React from 'react';
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from '../../../store/slices/auth/authSlice';
import styles from './UserEmail.module.scss';
import logout from '../../../assets/icons/logout.svg';

function UserEmail() {
    const email = useSelector((state) => state.auth.user?.email);
    const dispatch = useDispatch();

    const getAvatarLetter = (email) => {
        return email ? email.charAt(0).toUpperCase() : '';
    };

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <div className={styles.profile}>
            <NavLink
                className={styles.profileDetails}
                to='/account'
            >
                <div className={styles.profileAvatar}>
                    {getAvatarLetter(email)}
                </div>
                <div className={styles.profileEmail}>{email}</div>
            </NavLink>
            <img
                className={styles.logout}
                src={logout}
                alt="Logout user"
                onClick={handleLogout}
            />
        </div>
    );
}

export default UserEmail;
