import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from "../../../utils/translations/Translate";
import UserEmail from '../UserEmail/UserEmail';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

import balanceIcon from '../../../assets/icons/balance.svg';

import styles from './Header.module.scss';

function Header({ setSidebarActive, sidebarActive, balance }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    const { lang } = useSelector((state) => state.translations);

    const translation = new Translate('public/header', lang);
    const t = translation.getWord();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setSidebarActive((prevState) => !prevState);
    };

    const formatBalance = (amount) => {
        if (amount === undefined || amount === null) return "N/A";
        return `${amount.toLocaleString()} EUR`;
    };

    return (
        <header className={styles.header}>
            <div className={`${styles.logo_mobile} ${sidebarActive ? styles.active : ''}`}>
                <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.0957 22.654C15.2559 22.654 20.2497 17.6602 20.2497 11.5C20.2497 5.33981 15.2559 0.346001 9.0957 0.346001V22.654Z" fill="white" />
                    <path d="M24.7111 15.9616C27.1752 15.9616 29.1727 13.9641 29.1727 11.5C29.1727 9.03592 27.1752 7.0384 24.7111 7.0384C22.247 7.0384 20.2495 9.03592 20.2495 11.5C20.2495 13.9641 22.247 15.9616 24.7111 15.9616Z" fill="white" />
                    <path d="M4.63396 15.9616C7.09804 15.9616 9.09556 13.9641 9.09556 11.5C9.09556 9.03592 7.09804 7.0384 4.63396 7.0384C2.16989 7.0384 0.172363 9.03592 0.172363 11.5C0.172363 13.9641 2.16989 15.9616 4.63396 15.9616Z" fill="white" />
                </svg>
            </div>

            <div className={`${styles.balance} ${sidebarActive ? styles.active : ''}`}>
                <img src={balanceIcon} alt="Balance" />
                <div className={styles.balanceLabel}>
                    {t('Balance:')} {" "}
                    <span className={styles.balanceAmount}>
                        {formatBalance(balance)}
                    </span>
                </div>
            </div>
            <div className={styles.profile}>
                {!isMobile && <UserEmail />}
                {!isMobile && <LanguageSwitcher />}
            </div>

            <div className={`${styles.menu_mobile} ${sidebarActive ? styles.active : ''}`} onClick={toggleSidebar}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </header>
    );
}

export default Header;
