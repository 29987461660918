// import React, { useState, useEffect } from 'react';
// import { Route, Routes, useLocation } from "react-router-dom";
// import './scss/app.scss';
// import publicRoutes from "./config/routing/publicRoutes";
// import Sidebar from "./components/general/Sidebar/Sidebar";
// import Header from "./components/general/Header/Header";

// function App() {
//   const location = useLocation();
//   const routes = publicRoutes;
//   const [sidebarActive, setSidebarActive] = useState(false);

// useEffect(() => {
//   setSidebarActive(false);
// }, [location]);

//   return (
//       <Routes>
//         {routes.map((route) => (
//           route.isAuthPage ? (
//             <Route key={route.path} path={route.path} element={route.element} />
//           ) : (
//             <Route 
//               key={route.path} 
//               path={route.path} 
//               element={
//                 <>
//                   <Sidebar isActive={sidebarActive} />
//                   <div className="main">
//                     <Header setSidebarActive={setSidebarActive} sidebarActive={sidebarActive} />
//                     {route.element}
//                   </div>
//                 </>
//               }
//             />
//           )
//         ))}
//       </Routes>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserData, setToken, refreshAuthToken, logoutUser } from './store/slices/auth/authSlice';
import { fetchUserBalance } from './store/slices/partner/partnerSlice';
import { setLang } from './store/slices/translations/translationSlice';
import './scss/app.scss';
import publicRoutes from "./config/routing/publicRoutes";
import Sidebar from "./components/general/Sidebar/Sidebar";
import Header from "./components/general/Header/Header";

const AuthenticatedLayout = ({ balance, sidebarActive, setSidebarActive, children }) => (
  <>
    <Sidebar isActive={sidebarActive} setSidebarActive={setSidebarActive} />
    <div className="main">
      <Header balance={balance} setSidebarActive={setSidebarActive} sidebarActive={sidebarActive} />
      {children}
    </div>
  </>
);

const PrivateRoute = ({ element, token, user, isLoading }) => {
  if (isLoading) {
    return null;
  }

  if (!token || !user) {
    return <Navigate to="/signin" />;
  }

  return element;
};

const PublicRoute = ({ element, token, user }) => {
  const location = useLocation();

  if (token && user && location.pathname === "/signin") {
    return <Navigate to="/offerwall" replace />;
  }

  return element;
};

const getDefaultLanguage = (queryLang, storedLang) => queryLang || storedLang || 'en';

function App() {
  const [sidebarActive, setSidebarActive] = useState(false);
  const dispatch = useDispatch();
  const { user, token, isLoading } = useSelector((state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isLoading: state.auth.isLoading,
  }));
  const { balance } = useSelector((state) => state.partner);
  
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const langParam = searchParams.get('lang');

  useEffect(() => {
    setSidebarActive(false);
  }, [location]);

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    const newLang = getDefaultLanguage(langParam, storedLang);
    if (!storedLang || (langParam && storedLang !== langParam)) {
        dispatch(setLang(newLang));
        localStorage.setItem('lang', newLang);
    }
  }, [location, dispatch, langParam]);

  useEffect(() => {
    const checkAuth = async () => {
      const localToken = localStorage.getItem('token');
      const localRefresh = localStorage.getItem('refresh');

      if (localToken) {
        dispatch(setToken(localToken));
      }

      if (!user && localRefresh) {
        try {
          await dispatch(refreshAuthToken());
        } catch {
          dispatch(logoutUser());
        }
      }

      if (!localToken && !localRefresh) {
        dispatch(logoutUser());
      }
    };

    checkAuth();
  }, [dispatch, user]);

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("user"));

    if (!user && localUser) {
      dispatch({ type: "auth/setUser", payload: localUser });
    }

    if (token && !user) {
      dispatch(fetchUserData()).then((action) => {
        if (action.payload) {
          localStorage.setItem("user", JSON.stringify(action.payload));
          dispatch(fetchUserBalance());
        }
      });
    } else if (user) {
      dispatch(fetchUserBalance());
    }
  }, [token, user, dispatch]);

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.isAuthPage ? (
              <PublicRoute token={token} user={user} element={route.element} />
            ) : (
              <PrivateRoute
                token={token}
                user={user}
                isLoading={isLoading}
                element={
                  <AuthenticatedLayout
                    balance={balance}
                    sidebarActive={sidebarActive}
                    setSidebarActive={setSidebarActive}
                  >
                    {route.element}
                  </AuthenticatedLayout>
                }
              />
            )
          }
        />
      ))}
    </Routes>
  );
}

export default App;