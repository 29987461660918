import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { partnerApi } from '../../../api/partner';


export const fetchUserBalance = createAsyncThunk(
    "partner/fetchUserBalance",
    async (_, { rejectWithValue }) => {
        try {
            const response = await partnerApi.fetchUserBalance();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Failed to fetch user balance");
        }
    }
);


const initialState = {
    balance: null,
    loading: false,
    error: null,
};

const partnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        clearError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserBalance.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserBalance.fulfilled, (state, action) => {
                state.loading = false;
                state.balance = action.payload.data?.balance;
            })
            .addCase(fetchUserBalance.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { clearError } = partnerSlice.actions;
export default partnerSlice.reducer;
