import instance from '../utils/axios';

const API_HOST = process.env.REACT_APP_SERVICE_HOST;

export const streamsApi = {
    fetchStreams(query) {
        return instance(API_HOST).get(`/v1/user/stream?${query}`, { withCredentials: true });
    },
    searchStreams(page = 1, perPage = 10) {
        return instance(API_HOST).get(`/v1/user/stream-names?page=${page}&perPage=${perPage}`, { withCredentials: true });
    },
    searchStreamsWebSources(page = 1, perPage = 10) {
        return instance(API_HOST).get(`/v1/user/stream-web-sources?page=${page}&perPage=${perPage}`, { withCredentials: true });
    },
    fetchStreamById(id, lang = 'en') {
        return instance(API_HOST).get(`/v1/user/stream/${id}`, {
            params: { lang },
            withCredentials: true,
        });
    },
    createStream(data) {
        return instance(API_HOST, 'multipart/form-data').post(`/v1/user/stream`, data, { withCredentials: true });
    },
    deleteStream(id) {
        return instance(API_HOST).delete(`/v1/user/stream/${id}`, { withCredentials: true });
    },
};