import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { streamsApi } from '../../../api/streams';

export const fetchStreams = createAsyncThunk(
    "streams/fetchStreams",
    async ({ page = 1, perPage = 10, filters = {}, lang = 'en' }, { rejectWithValue }) => {
        try {
            let query = `page=${page}&perPage=${perPage}&lang=${lang}`;
            Object.keys(filters).forEach((key) => {
                const value = filters[key];
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        query += `&${key}[]=${item}`;
                    });
                } else {
                    query += `&${key}=${value}`;
                }
            });

            const response = await streamsApi.fetchStreams(query);
            return {
                items: response.data.data.items,
                totalPages: response.data.data.pagination.max,
                page,
            };
        } catch (error) {
            const message = error.response?.data?.message || "Failed to fetch streams";
            return rejectWithValue(message);
        }
    }
);

export const searchStreams = createAsyncThunk(
    'streams/searchStreams',
    async ({page = 1, perPage = 10 }, { rejectWithValue }) => {
        try {
            const response = await streamsApi.searchStreams(page, perPage);
            return response.data.data.items;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to search streams';
            return rejectWithValue(message);
        }
    }
);

export const searchStreamsWebSources = createAsyncThunk(
    'streams/searchStreamsWebSources',
    async ({page = 1, perPage = 10 }, { rejectWithValue }) => {
        try {
            const response = await streamsApi.searchStreamsWebSources(page, perPage);
            return response.data.data.items;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to search streams';
            return rejectWithValue(message);
        }
    }
);

export const fetchStreamDetails = createAsyncThunk(
    'streams/fetchStreamDetails',
    async ({ id, lang = 'en' }, { rejectWithValue }) => {
        try {
            const response = await streamsApi.fetchStreamById(id, lang);
            return response.data.data;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to fetch stream details';
            return rejectWithValue(message);
        }
    }
);

export const createStream = createAsyncThunk(
    'streams/createStream',
    async (streamData, { rejectWithValue }) => {
        try {
            const response = await streamsApi.createStream(streamData);
            return response.data.data;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to create stream';
            return rejectWithValue(message);
        }
    }
);

export const deleteStream = createAsyncThunk(
    'streams/deleteStream',
    async (id, { rejectWithValue }) => {
        try {
            await streamsApi.deleteStream(id);
            return id;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to delete stream';
            return rejectWithValue(message);
        }
    }
);

// Slice
const streamsSlice = createSlice({
    name: 'streams',
    initialState: {
        streams: [],
        streamsNames: [],
        streamsWebSources: [],
        streamDetails: null,
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        filters: {},
        loading: false,
        error: null,
    },
    reducers: {
        resetStreamDetails(state) {
            state.streamDetails = null;
        },
        setFilters(state, action) {
            state.filters = action.payload; 
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStreams.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStreams.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.streams = payload.items || [];
                state.currentPage = payload.page || 1;
                state.perPage = payload.perPage || 10;
                state.totalPages = payload.totalPages;
            })
            .addCase(fetchStreams.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(searchStreams.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchStreams.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.streamsNames = payload;
            })
            .addCase(searchStreams.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(searchStreamsWebSources.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchStreamsWebSources.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.streamsWebSources = payload;
            })
            .addCase(searchStreamsWebSources.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchStreamDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStreamDetails.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.streamDetails = payload;
            })
            .addCase(fetchStreamDetails.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(createStream.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createStream.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.streams.push(payload);
            })
            .addCase(createStream.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deleteStream.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteStream.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.streams = state.streams.filter((stream) => stream.id !== payload);
            })
            .addCase(deleteStream.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export const { resetStreamDetails, setFilters } = streamsSlice.actions;

export default streamsSlice.reducer;
