import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { offersApi } from '../../../api/offers';

export const fetchOffers = createAsyncThunk(
    'offers/fetchOffers',
    async ({ page = 1, perPage = 10 }, { rejectWithValue }) => {
        try {
            const response = await offersApi.fetchOffers(page, perPage);
            return {
                items: response.data.data.items,
                pagination: response.data.data.pagination,
            };
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to fetch offers';
            return rejectWithValue(message);
        }
    }
);

export const searchOffers = createAsyncThunk(
    'offers/searchOffers',
    async (search, { rejectWithValue }) => {
        try {
            const response = await offersApi.searchOffers(search);
            return response.data.data.items;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to search offers';
            return rejectWithValue(message);
        }
    }
);

export const fetchCategories = createAsyncThunk(
    'offers/fetchCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await offersApi.fetchCategories();
            return response.data.data.items;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to fetch categories';
            return rejectWithValue(message);
        }
    }
);

export const fetchOfferDetails = createAsyncThunk(
    'offers/fetchOfferDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await offersApi.fetchOfferById(id);
            return response.data.data;
        } catch (error) {
            const message = error.response?.data?.message || 'Failed to fetch offer details';
            return rejectWithValue(message);
        }
    }
);

const offersSlice = createSlice({
    name: 'offers',
    initialState: {
        offers: [],
        offersNames: [],
        offerDetails: null,
        categories: [],
        filteredOffers: [],
        pagination: {
            page: 1,
            max: 1,
            hasMore: false,
        },
        loading: false,
        error: null,
        selectedCategories: [],
    },
    reducers: {
        setFilteredOffers(state, action) {
            state.filteredOffers = action.payload;
        },
        toggleCategory(state, action) {
            const category = action.payload;
            if (state.selectedCategories.includes(category)) {
                state.selectedCategories = state.selectedCategories.filter((cat) => cat !== category);
            } else {
                state.selectedCategories.push(category);
            }
        },
        resetFilters(state) {
            state.selectedCategories = [];
            state.filteredOffers = state.offers;
        },
        resetOfferDetails(state) {
            state.offerDetails = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOffers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOffers.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.offers = payload.items;
                state.filteredOffers = payload.items;
                state.pagination = payload.pagination;
            })
            .addCase(fetchOffers.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.categories = payload;
            })
            .addCase(fetchCategories.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(searchOffers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchOffers.fulfilled, (state, action) => {
                state.loading = false;
                state.offersNames = action.payload;
            })
            .addCase(searchOffers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch offers";
            })
            .addCase(fetchOfferDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOfferDetails.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.offerDetails = payload;
            })
            .addCase(fetchOfferDetails.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export const { setFilteredOffers, toggleCategory, resetFilters, resetOfferDetails } = offersSlice.actions;

export default offersSlice.reducer;
