import instance from '../utils/axios';

const API_HOST = process.env.REACT_APP_SERVICE_HOST;

export const paymentsApi = {
    fetchPayments(page = 1, perPage = 100) {
        return instance(API_HOST).get(`/v1/user/payments?page=${page}&perPage=${perPage}`, { withCredentials: true });
    },
    fetchPaymentMethods() {
        return instance(API_HOST).get(`/v1/user/payment-methods-all`, { withCredentials: true });
    },
    createPayment(paymentData) {
        return instance(API_HOST, 'multipart/form-data').post(`/v1/user/payment`, paymentData, { withCredentials: true });
    },
};
