import { createSlice } from '@reduxjs/toolkit'
import { LANGUAGES } from "../../../constants/constants.mjs";


const initialState = {
  	allLang: LANGUAGES,
  	lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
}


export const translationSlice = createSlice({
	name: 'translations',
	initialState,
	reducers: {
		setLang(state, action) {
			state.lang = action.payload
			localStorage.setItem('lang', action.payload)
		}
	}
})

export const {
	setLang,
} = translationSlice.actions

export default translationSlice.reducer;