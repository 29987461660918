import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../../store/slices/translations/translationSlice';
import styles from './LanguageSwitcher.module.scss';

const languages = {
    en: 'EN',
    fr: 'FR',
    es: 'ES'
};

function LanguageSwitcher({ isAuthPage }) {
    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.translations);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLanguageChange = (language) => {
        dispatch(setLang(language));
        setIsDropdownOpen(false);
    };

    return (
        <div className={`${styles.languageSwitcher} ${isAuthPage ? styles.authPageSwitcher : '' }`}>
            <div className={styles.languageDropdown}>
                <button className={styles.languageButton} onClick={toggleDropdown}>
                    {languages[lang]}
                    {isAuthPage ? (
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.99991 3.63083L6.71524 0.915334C6.80758 0.823111 6.92363 0.775889 7.06341 0.773667C7.20308 0.771556 7.32124 0.818778 7.41791 0.915334C7.51447 1.012 7.56274 1.12911 7.56274 1.26667C7.56274 1.40422 7.51447 1.52133 7.41791 1.618L4.42174 4.61417C4.3593 4.6765 4.29347 4.7205 4.22424 4.74617C4.15502 4.77183 4.08024 4.78467 3.99991 4.78467C3.91958 4.78467 3.8448 4.77183 3.77558 4.74617C3.70636 4.7205 3.64052 4.6765 3.57808 4.61417L0.581911 1.618C0.489689 1.52567 0.442467 1.40961 0.440245 1.26983C0.438133 1.13017 0.485355 1.012 0.581911 0.915333C0.678578 0.818778 0.795689 0.7705 0.933244 0.7705C1.0708 0.7705 1.18791 0.818778 1.28458 0.915333L3.99991 3.63083Z" fill="#0E0E0E"/>
                        </svg>
                    ): (
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00015 3.63083L6.71549 0.915334C6.80782 0.823111 6.92388 0.775889 7.06365 0.773667C7.20332 0.771556 7.32149 0.818778 7.41815 0.915334C7.51471 1.012 7.56299 1.12911 7.56299 1.26667C7.56299 1.40422 7.51471 1.52133 7.41815 1.618L4.42199 4.61417C4.35954 4.6765 4.29371 4.7205 4.22449 4.74617C4.15527 4.77183 4.08049 4.78467 4.00015 4.78467C3.91982 4.78467 3.84504 4.77183 3.77582 4.74617C3.7066 4.7205 3.64077 4.6765 3.57832 4.61417L0.582155 1.618C0.489933 1.52567 0.442711 1.40961 0.440489 1.26983C0.438377 1.13017 0.4856 1.012 0.582155 0.915333C0.678822 0.818778 0.795933 0.7705 0.933488 0.7705C1.07104 0.7705 1.18815 0.818778 1.28482 0.915333L4.00015 3.63083Z" fill="#E8EAED"/>
                        </svg>
                    )}



                </button>
                {isDropdownOpen && (
                    <ul className={styles.languageList}>
                        {Object.keys(languages).map((language) => (
                            <li
                                key={language}
                                className={`${styles.languageOption} ${lang === language ? styles.active : ''}`}
                                onClick={() => handleLanguageChange(language)}
                            >
                                {languages[language]}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default LanguageSwitcher;
