import instance from '../utils/axios';

const API_HOST = process.env.REACT_APP_SERVICE_HOST;

export const postbackApi = {
  fetchPostbacks(page = 1, perPage = 100) {
    return instance(API_HOST).get(`/v1/user/postback-urls?page=${page}&perPage=${perPage}`, { withCredentials: true });
  },
  fetchPostbackTriggers() {
    return instance(API_HOST).get(`/v1/postback-triggers-types`, { withCredentials: true });
  },
  createPostback(data) {
    return instance(API_HOST, 'multipart/form-data').post(`/v1/user/postback-url`, data, { withCredentials: true });
  },
  updatePostback(id, data) {
    return instance(API_HOST, 'multipart/form-data').post(`/v1/user/postback-url/${id}`, data, { withCredentials: true });
  },
  deletePostback(id) {
    return instance(API_HOST).delete(`/v1/user/postback-url/${id}`, { withCredentials: true });
  },
};
