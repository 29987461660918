import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../../utils/axios';

const AUTH_HOST = process.env.REACT_APP_AUTH_HOST;

export const authApi = {
  login(data) {
    return instance(AUTH_HOST).post('/v1/auth', data, { withCredentials: true });
  },
  register(data) {
    return instance(AUTH_HOST, 'multipart/form-data').post('/v1/registration', data, { withCredentials: true });
  },
  fetchUserData() {
    return instance(AUTH_HOST).get('/v1/me', { withCredentials: true });
  },
  refreshToken() {
    return instance(AUTH_HOST).get('/v1/refresh', { withCredentials: true });
  },
  logout() {
    return instance(AUTH_HOST).post('/v1/logout', {}, { withCredentials: true });
  },
};

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refresh') || '',
  loading: false,
  isLoading: false,
  status: '',
  message: '',
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authApi.login(params);

      localStorage.setItem('token', data.data.token);
      localStorage.setItem('refresh', data.data.refresh);

      dispatch(setToken(data.data.token));
      dispatch(setRefreshToken(data.data.refresh));

      await dispatch(fetchUserData());

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Login failed');
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await authApi.register(params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Registration failed');
    }
  }
);

export const refreshAuthToken = createAsyncThunk(
  'auth/refreshAuthToken',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { refreshToken } = getState().auth;
    if (!refreshToken) {
      dispatch(logout());
      return rejectWithValue('Refresh token not found');
    }

    try {
      const { data } = await authApi.refreshToken({ refresh_token: refreshToken });

      localStorage.setItem('token', data.data.token);
      localStorage.setItem('refresh', data.data.refresh);

      dispatch(setToken(data.data.token));
      dispatch(setRefreshToken(data.data.refresh));

      return data.data;
    } catch (error) {
      dispatch(logout());
      return rejectWithValue(error.response?.data || 'Failed to refresh token');
    }
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await authApi.logout();

      localStorage.removeItem('token');
      localStorage.removeItem('refresh');

      dispatch(logout());
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Logout failed');
    }
  }
);

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await authApi.fetchUserData();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user data');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
      localStorage.setItem('refresh', action.payload);
    },
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    removeToken(state) {
      state.token = '';
      localStorage.removeItem('token');
    },
    removeRefreshToken(state) {
      state.refreshToken = '';
      localStorage.removeItem('refresh');
    },
    resetStatus(state) {
      state.status = '';
      state.message = '';
    },
    logout(state) {
      state.token = '';
      state.refreshToken = '';
      state.user = null;
      state.isLoading = false;
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.status = '';
        state.message = '';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'success';
        state.token = action.payload.data.token;
        state.refreshToken = action.payload.data.refresh;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.status = 'error';
        state.message = action.payload;
      });

    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.status = '';
        state.message = '';
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.status = 'success';
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.status = 'error';
        state.message = action.payload;
      });

    builder
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.status = 'error';
        state.message = action.payload;
      });

    builder
    .addCase(refreshAuthToken.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refresh;
    })
      .addCase(refreshAuthToken.rejected, (state) => {
        state.token = '';
        state.refreshToken = '';
        state.user = null;
      });

    builder
    .addCase(logoutUser.fulfilled, (state) => {
      state.token = '';
      state.refreshToken = '';
      state.user = null;
    });
  },
});

export const { setToken, setRefreshToken, setUser, resetStatus, logout } = authSlice.actions;

export default authSlice.reducer;
