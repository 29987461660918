import axios from 'axios';
import store from '../store/store';
import { refreshAuthToken, logout } from '../store/slices/auth/authSlice';

const instance = (url, contentType = "application/json") => {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": contentType,
      "Accept": "/",
    },
    baseURL: url,
    withCredentials: true,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('token');

      if (token) {
        config.headers['X-App-Token'] = token;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response?.status === 400 &&
        error.response?.data?.message === 'Token expired' &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          await store.dispatch(refreshAuthToken());
          
          const newToken = localStorage.getItem('token');

          if (newToken) {
            originalRequest.headers['X-App-Token'] = newToken;
          }

          return axios(originalRequest);
        } catch {
          store.dispatch(logout());
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default instance;
