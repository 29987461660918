import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth/authSlice';
import offersReducer from './slices/offers/offersSlice';
import streamsReducer from './slices/streams/streamsSlice';
import paymentsReducer from './slices/account/paymentsSlice';
import postbackReducer from './slices/account/postbackSlice';
import statsReducer from './slices/stats/statsSlice';
import partnerReducer from './slices/partner/partnerSlice';
import translationReducer from './slices/translations/translationSlice';



const store = configureStore({
    reducer: {
        auth: authReducer,
        offers: offersReducer,
        streams: streamsReducer,
        payments: paymentsReducer,
        postback: postbackReducer,
        stats: statsReducer,
        partner: partnerReducer,
        translations: translationReducer,

    },
});

export default store;
