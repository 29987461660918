import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { statsApi } from "../../../api/stats";

export const fetchChartData = createAsyncThunk(
    "stats/fetchChartData",
    async ({ dateFrom, dateTo }, { rejectWithValue }) => {
        try {
            const { data } = await statsApi.fetchChartData(dateFrom, dateTo);
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Failed to fetch chart statistics");
        }
    }
);

export const fetchTableData = createAsyncThunk(
    "stats/fetchTableData",
    async ({ createdFrom, createdTo, page, perPage, filters = {} }, { rejectWithValue }) => {
        try {
            let query = `page=${page}&perPage=${perPage}&createdFrom=${encodeURIComponent(createdFrom)}&createdTo=${encodeURIComponent(createdTo)}`;
            
            Object.keys(filters).forEach((key) => {
                const value = filters[key];
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        query += `&${key}[]=${encodeURIComponent(item)}`;
                    });
                } else {
                    query += `&${key}=${encodeURIComponent(value)}`;
                }
            });

            const { data } = await statsApi.fetchTableData(query);
            return { 
                items: data.data.items, 
                totalPages: data.data.pagination.max 
            };
        } catch (error) {
            const message = error.response?.data?.message || "Failed to fetch table data";
            return rejectWithValue(message);
        }
    }
);

export const exportToCsv = createAsyncThunk(
    "stats/exportToCsv",
    async ({ createdFrom, createdTo, filters, page = 1, perPage = 10, onProgress }, { rejectWithValue }) => {
        try {
            const initialResponse = await statsApi.startExport(createdFrom, createdTo, filters, page, perPage);
            const { hash, scrollId, pagination, link } = initialResponse.data.data;

            if (!hash || !scrollId || !pagination) {
                throw new Error("Invalid response structure from the export API");
            }

            const totalPages = pagination.maxPage;

            for (let page = 1; page <= totalPages; page++) {
                await statsApi.fetchExportPage(hash, scrollId, page, perPage, createdFrom, createdTo, filters);

                if (onProgress && typeof onProgress === "function") {
                    const progress = Math.round((page / totalPages) * 100);
                    onProgress(progress);
                }
            }

            return link;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Export failed");
        }
    }
);

const initialState = {
    chartData: {},
    tableData: [],
    loading: false,
    error: null,
    exportSuccess: false,
    exportLink: null,
};

const statsSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {
        clearStatsError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChartData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchChartData.fulfilled, (state, action) => {
                state.loading = false;
                state.chartData = action.payload || {};
            })
            .addCase(fetchChartData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchTableData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTableData.fulfilled, (state, action) => {
                state.loading = false;
                state.tableData = action.payload.items;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(fetchTableData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(exportToCsv.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.exportSuccess = false;
                state.exportLink = null;
            })
            .addCase(exportToCsv.fulfilled, (state, action) => {
                state.loading = false;
                state.exportSuccess = true;
                state.exportLink = action.payload;
            })
            .addCase(exportToCsv.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.exportSuccess = false;
            });
    },
});

export const { clearStatsError } = statsSlice.actions;

export default statsSlice.reducer;
