import { lazy, Suspense } from 'react';
import {
	ERROR_PAGE_404_PATH,
	SIGNIN_PATH,
	SIGNUP_PATH,
	OFFERWALL_PATH,
	OFFER_DETAILS_PATH,
	STREAM_DETAILS_PATH,
	ACCOUNT_PATH,
	STATS_PATH,
	STREAMS_PATH
} from "./path";

import { Loader } from '../../components/general/Loader/Loader'

const SignInPage = lazy(() => import('../../pages/public/SignIn/SignIn'))
const SignUpPage = lazy(() => import('../../pages/public/SignUp/SignUp'))
const OfferwallPage = lazy(() => import('../../pages/public/Offerwall/Offerwall'))
const OfferDetailsPage = lazy(() => import('../../pages/public/OfferDetails/OfferDetails'))
const StreamDetailsPage = lazy(() => import('../../pages/public/StreamDetails/StreamDetails'))
const AccountPage = lazy(() => import('../../pages/public/Account/Account'))
const StreamsPage = lazy(() => import('../../pages/public/Streams/Streams'))
const StatsPage = lazy(() => import('../../pages/public/Stats/Stats'))
const NotFound = lazy(() => import('../../pages/NotFound'))


const SIGNIN = {
	element: (
		<Suspense fallback={<Loader />}>
			<SignInPage />
		</Suspense>
	),
	path: SIGNIN_PATH,
	isAuthPage: true,
};

const SIGNUP = {
	element: (
		<Suspense fallback={<Loader />}>
			<SignUpPage />
		</Suspense>
	),
	path: SIGNUP_PATH,
	isAuthPage: true,
};

const OFFERWALL = {
	element: (
		<Suspense fallback={<Loader />}>
			<OfferwallPage />
		</Suspense>
	),
	path: OFFERWALL_PATH,
};

const OFFER_DETAILS = {
	element: (
		<Suspense fallback={<Loader />}>
			<OfferDetailsPage />
		</Suspense>
	),
	path: OFFER_DETAILS_PATH,
};

const STREAMS = {
	element: (
		<Suspense fallback={<Loader />}>
			<StreamsPage />
		</Suspense>
	),
	path: STREAMS_PATH,
};

const STREAM_DETAILS = {
	element: (
		<Suspense fallback={<Loader />}>
			<StreamDetailsPage />
		</Suspense>
	),
	path: STREAM_DETAILS_PATH,
};

const ACCOUNT = {
	element: (
		<Suspense fallback={<Loader />}>
			<AccountPage />
		</Suspense>
	),
	path: ACCOUNT_PATH,
};
const STATS = {
	element: (
		<Suspense fallback={<Loader />}>
			<StatsPage />
		</Suspense>
	),
	path: STATS_PATH,
};

const NOT_FOUND = {
	element: (
		<Suspense fallback={<Loader />}>
			<NotFound />
		</Suspense>
	),
	path: ERROR_PAGE_404_PATH,
};

const routes = [
	SIGNIN,
	SIGNUP,
	OFFERWALL,
	OFFER_DETAILS,
	STREAM_DETAILS,
	STREAMS,
	ACCOUNT,
	STATS,
	NOT_FOUND
];

export default routes;