import instance from '../utils/axios';

const API_HOST = process.env.REACT_APP_SERVICE_HOST;

export const offersApi = {
    fetchOffers(page = 1, perPage = 10) {
        return instance(API_HOST).get(`/v1/user/offer?page=${page}&perPage=${perPage}`, { withCredentials: true });
    },
    searchOffers(search) {
        return instance(API_HOST).get(`/v1/user/offer/search-for-select?search=${search}`, { withCredentials: true });
    },
    fetchCategories() {
        return instance(API_HOST).get(`/v1/offer-categories`, { withCredentials: true });
    },
    fetchOfferById(id) {
        return instance(API_HOST).get(`/v1/user/offer/${id}`, { withCredentials: true });
    },
};